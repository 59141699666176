import React, { Component } from 'react'
import SlickSlider from 'react-slick'

import WOW from 'wow.js/dist/wow.js'

const ns = 'slider-fancy'

class Slider extends Component {
  constructor(props) {
    super(props)

    this.settings = {
      dots: true,
      infinite: false,
      speed: 500,
      arrows: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      centerMode: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            centerPadding: '0px'
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0,
            centerPadding: '0px'
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerPadding: '0px'
          }
        }
      ]
    }
  }
  comonentDidMount() {
    new WOW({
      offset: 150,
      mobile: false
    }).init()
  }

  render() {
    const { slides } = this.props
    return (
      <section className={`${ns} wow fadeInUp`} data-wow-duration="1.5s">
        <SlickSlider {...this.settings}>
          {slides &&
            slides.map(function(slide, i) {
              return (
                <div key={i} className={`${ns}__slide ${ns}__slide--index-${i}`}>
                  <img src={slide.image.url} alt={i} />
                </div>
              )
            })}
        </SlickSlider>
      </section>
    )
  }
}

export default Slider
