import React, { Component } from 'react'
import { connect } from 'react-redux'

// Components
import NewsList from '../../Components/NewsList'
import Loader from '../../Components/Loader'

// Actions
import { newsPagefetchArticles } from '../../store/actions/newspage'

const ns = 'page-news'

class News extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true
    }
  }
  componentDidMount() {
    this.props.fetchPageData()
  }

  componentDidUpdate(prevProps) {
    if (this.props.newspage !== prevProps.newspage) {
      this.animationTimeout()
    }
  }

  // For Page Reload to init WOWjs again
  componentWillReceiveProps(props) {
    if (this.props.newspage !== props.newspage) {
      this.animationTimeout()
    }
  }

  animationTimeout() {
    setTimeout(() => {
      this.setState({
        loading: false
      })
      window.scrollTo(0, 0)
    }, 500)
  }

  render() {
    return (
      <div>
        {this.state.loading ? (
          <Loader />
        ) : (
          <div className={`${ns} page-wrapper`}>
            {!!this.props.newspage && (
              <div className={`page-wrapper`}>
                <NewsList list={this.props.newspage} />
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    newspage: state.newspage
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchPageData: () => dispatch(newsPagefetchArticles())
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(News)
