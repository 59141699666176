import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'
import { teampage, team, founders, teamMember } from './teamPage'
import { partnerpage, partners, partnerDetail, partnerLinkedArticles } from './partnerpage'
import { aboutpage } from './aboutpage'
import { contactpage } from './contactpage'
import { newspage } from './newspage'

export default combineReducers({
  aboutpage,
  contactpage,
  partnerpage,
  partners,
  partnerDetail,
  partnerLinkedArticles,
  teampage,
  team,
  founders,
  teamMember,
  newspage,
  routing: routerReducer
})
