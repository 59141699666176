import React, { Component } from 'react'
import { connect } from 'react-redux'

// Components
import Hero from '../../Components/Hero'
import CopyBlock from '../../Components/CopyBlock'
import SplitColumns from '../../Components/SplitColumns'
import CopyList from '../../Components/CopyList'
import Loader from '../../Components/Loader'

// Actions
import { aboutPageFetchData } from '../../store/actions/aboutpage'

const ns = 'page-about'

class About extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true
    }
  }
  componentDidMount() {
    this.props.fetchPageData()
  }

  componentDidUpdate(prevProps) {
    if (this.props.aboutpage !== prevProps.aboutpage) {
      this.animationTimeout()
    }
  }

  // For Page Reload to init WOWjs again
  componentWillReceiveProps(props) {
    if (this.props.aboutpage !== props.aboutpage) {
      this.animationTimeout()
    }
  }

  animationTimeout() {
    setTimeout(() => {
      this.setState({
        loading: false
      })
      window.scrollTo(0, 0)
    }, 500)
  }

  render() {
    return (
      <div>
        {this.state.loading ? (
          <Loader />
        ) : (
          <div className={`${ns}`}>
            { console.log(this.props.aboutpage.data)}
            {!!this.props.aboutpage.data && (
              <div className="page-wrapper">
                <Hero
                  headline={this.props.aboutpage.data.headline[0].text}
                  eyebrow={this.props.aboutpage.data.eyebrow[0].text}
                  alt={this.props.aboutpage.data.image.alt}
                  bgImage={this.props.aboutpage.data.image.url}
                  modifier="about"
                />
                <CopyBlock copy={this.props.aboutpage.data.quote[0].text} />
                <CopyList list={this.props.aboutpage.data.value_list} modifier="values" />
                <SplitColumns leftColumn={this.props.aboutpage.data.body[0]} rightColumn={this.props.aboutpage.data.body[1]} />
                <CopyList
                  list={this.props.aboutpage.data.resources_list}
                  title={this.props.aboutpage.data.resources_title[0].text}
                  modifier="resources"
                  slider={true}
                  bgImage={this.props.aboutpage.data.image1.url}
                />
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    aboutpage: state.aboutpage
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchPageData: () => dispatch(aboutPageFetchData())
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(About)
