import React from 'react'

// Slide
import Slide from 'react-slick'

const NewsHome = (props) => {
  const ns = props.ns
  
  return (
    <section className={`${ns}__news-background`}>
      <div className={`grid-container`}>
        <div className={`${ns}__news-wrapper wow fadeInUp`} data-wow-duration="1.5s">
          <div className={`${ns}__news--header-wrapper`}>
            <h1 className={`${ns}__headline-3 ${ns}__headline-3-news`}>{props.headline}</h1>
            <a href="/news" className={`${ns}__news--link hero__main-link`}>
              View All
            </a>
          </div>
          <ul className={`${ns}__news-list`}>
            {props.list.map((listItem, index) => (
              <a href={listItem.news_link.url} key={index} target="_blank" rel="noopener noreferrer">
                <li className={`${ns}__news-list-item`}>
                  <div className={`${ns}__news-list-image-wrapper`}>
                    <div className={`${ns}__news-list-image`} style={{ backgroundImage: `url(${listItem.news_image.url})` }} />
                  </div>

                  <p className={`${ns}__news--title`}>{listItem.news_title[0].text}</p>
                  <p className={`${ns}__news--source`}>{listItem.news_source[0].text}</p>
                  <p className={`${ns}__news--description`}>{listItem.news_description[0].text}</p>
                </li>
              </a>
            ))}
          </ul>

          <div className={`${ns}--controls`}>
            <Slide {...props.settings}>
              {props.list.map((listItem, index) => (
                <li className={`${ns}__news-list-item`} key={index}>
                  <a href={listItem.news_link.url}>
                    <div className={`${ns}__news-list-image-wrapper`}>
                      <div className={`${ns}__news-list-image`} style={{ backgroundImage: `url(${listItem.news_image.url})` }} />
                    </div>
                  </a>

                  <a href={listItem.news_link.url}>
                    <p className={`${ns}__news--title`}>{listItem.news_title[0].text}</p>
                    <p className={`${ns}__news--source`}>{listItem.news_source[0].text}</p>
                    <p className={`${ns}__news--description`}>{listItem.news_description[0].text}</p>
                  </a>
                </li>
              ))}
            </Slide>
          </div>
        </div>
      </div>
    </section>
  )
}

export default NewsHome