import React, { Component } from 'react'
import { Link } from 'react-router-dom'

// import logo from '../../assets/images/acg-logo-black.png'

// Prsimic
import Prismic from 'prismic-javascript'
import { PRISMIC_API_URL } from '../../config'

import mailchimp from '../../mailchimp'

const ns = 'footer'

const navList = [
  {
    url: '/partner-brands',
    title: 'Partner Brands'
  },
  {
    url: '/about',
    title: 'About'
  },
  {
    url: '/team',
    title: 'Team'
  },
  {
    url: '/news',
    title: 'News'
  },
  {
    url: '/contact',
    title: 'Contact'
  }
]

class Footer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      doc: null,
      formEmailAddress: '',
      formErrorStatus: false,
      formMessage: '',
      formSubmitted: false,
    }

    this.handleFormChange = this.handleFormChange.bind(this)
    this.handleFormSubmit = this.handleFormSubmit.bind(this)
  }

  componentDidMount() {
    // this.props.fetchPageData()
    Prismic.getApi(PRISMIC_API_URL)
      .then(api => {
        return api.query(Prismic.Predicates.at('document.type', 'footer'), {})
      })
      .then(response => {
        if (response) {
          // console.log(response.results[0])
          this.setState({
            doc: response.results[0],
            show: true
          })
        }
      })
  }

  handleFormChange(event) {
    this.setState({
      formEmailAddress: event.currentTarget.value
    })
  }

  handleFormSubmit(event) {
    event.preventDefault()

    const {
      formEmailAddress
    } = this.state

    // can change messages like so
    // set before calling subscribeAnAddress()
    // mailchimp.msgEmptyField = 'enter something dumbass'

    /* message properties
    msgAlreadySubscribed
    msgEmailInvalid
    msgEmptyField
    msgRequestError
    msgSuccess
    msgTooManyRecentRequests
    */

    mailchimp.subscribeAnAddress(formEmailAddress, (response) => {
      const {
        error,
        message,
      } = response

      this.setState({
        formEmailAddress: '', // clear email address field
        formErrorStatus: error,
        formMessage: message,
        formSubmitted: true,
      })
    })
    
    this.setState({show: true})

    setTimeout(() => {
      this.setState({show: false})
    }, 4000);
  }

  render() {
    const nsHeader = this.props.isHomePage ? ns + ' header--transparent' : ns

    const {
      formEmailAddress,
      formErrorStatus,
      formMessage,
      formSubmitted,
    } = this.state

    return (
      <footer className={nsHeader}>
        <div className={`${ns}__container grid-container`}>
          <div className={`${ns}__menu`}>
            <ul className={`${ns}__menu--desktop`}>
              {navList.map((item, index) => {
                let menuClass = this.props.location === item.url ? ns + '__menu--item active' : ns + '__menu--item'
                return (
                  <li className={menuClass} key={index}>
                    <Link className={`${ns}__link`} to={item.url}>
                      {item.title}
                    </Link>
                  </li>
                )
              })}
            </ul>
          </div>

          <div className={`${ns}__newsletter`}>
            <form className={`${ns}__newsletter--form-box`} action="">
              <label> Join our newsletter</label>
              <div className={`${ns}__newsletter--form`}>
                {/*  className="error" */}
                <input type="text" id="email" className={formSubmitted && formErrorStatus === true ? 'error' : ''} placeholder="Enter your email" value={formEmailAddress} onChange={this.handleFormChange} />
                <button className={`${ns}__newsletter--button`} onClick={this.handleFormSubmit}>Subscribe</button>
              </div>
            </form>
            {(formSubmitted && formErrorStatus === false) && (
              <span className={`${ns}__newsletter--success`}>{this.state.show ? formMessage : ''}</span>
            )}
            {(formSubmitted && formErrorStatus === true) && (
              <span className={`${ns}__newsletter--error`}>{this.state.show ? formMessage : ''}</span>
            )}
          </div>
        </div>

        {/* Footer Bottom */}
        {!!this.state.doc && (
          <div className={`${ns}__bottom grid-container`}>
            <div className={`${ns}__bottom--copyright`}>{this.state.doc.data.copyright_text[0].text} <Link to='/disclosures&termsofuse' className={`${ns}__bottom--disclosure`}>  Important Disclosures & Terms of Use</Link></div>

            <div className={`${ns}__bottom--social`}>
              {this.state.doc.data.social_media.map((social, index) => (
                <a href={social.social_media_link.url} target="_blank" rel="noopener noreferrer" className={`${ns}__bottom--link`} key={index}>
                  <div className={`${ns}__bottom--social-title`}>{social.social_media_platform[0].text}</div>
                </a>
              ))}
            </div>
          </div>
        )}
      </footer>
    )
  }
}

export default Footer
