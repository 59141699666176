import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import ReactCSSTransitionGroup from 'react-addons-css-transition-group'

import WOW from 'wow.js/dist/wow.js'

// Actions
import { teamMemberFetchData } from '../../store/actions/teampage'

// Components
import Loader from '../../Components/Loader'

const ns = 'page-team-detail'
const td = 'team-detail'

class TeamDetail extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      memberPath: this.props.location.pathname.split('/')[2]
    }
  }

  componentDidMount() {
    this.props.fetchPageData(this.state.memberPath)
    new WOW({
      offset: 150,
      mobile: false
    }).init()
  }

  componentDidUpdate(prevProps) {
    if (this.props.teamMember !== prevProps.teamMember) {
      setTimeout(() => {
        this.setState({
          loading: false
        })
        window.scrollTo(0, 0)
      }, 500)
    }
  }

  render() {
    return (
      <ReactCSSTransitionGroup
        className="stage"
        transitionName="crossfade"
        transitionAppear={true}
        transitionAppearTimeout={500}
        transitionEnterTimeout={500}
        transitionLeaveTimeout={300}
      >
        {this.state.loading ? (
          <Loader />
        ) : (
          <div className={`${ns} page-wrapper`}>
            {this.props.teamMember.data && (
              <section className={`${td}`}>
                <div className="grid-container wow fadeInUp">
                  <div className={`${td}__back`}>
                  <Link to="/team">
                    <svg width='50' height='7' viewBox='0 0 50 7' xmlns='http://www.w3.org/2000/svg'>
                        <g id='Mobile' fill='none' fillRule='evenodd'>
                            <g id='partner-brands-detail-mobile' transform='translate(-20 -101)' fill='#FFF'>
                                <g id='hero' transform='translate(0 70)'>
                                    <g id='cta' transform='translate(19 23)'>
                                        <path d='M5.52478571,12 L5.52478571,14.5646804 L0.984375,11.5977318 L5.52478571,8.63078318 L5.52478571,11 L51,11 L51,12 L5.52478571,12 Z'
                                        id='arrow' />
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                    </Link>
                    <Link to="/team" className={`${td}__link`}>
                      All Team
                    </Link>
                  </div>
                  <div className={`${td}__grid`}>
                    <div className={`${td}__col`}>
                      {this.props.teamMember.data.image.url && (
                        <img src={this.props.teamMember.data.image.url} alt={this.props.teamMember.data.name[0].text} />
                      )}
                    </div>
                    <div className={`${td}__col`}>
                      {this.props.teamMember.data.name[0].text && <h1 className={`${td}__headline`}>{this.props.teamMember.data.name[0].text}</h1>}
                      {this.props.teamMember.data.position[0].text && (
                        <p className={`${td}__position`}>
                          {this.props.teamMember.data.position.map(function(p, i) {
                            return <span key={i}>{p.text}<br/></span>
                          })}                          
                        </p>
  
                      )}
                      {this.props.teamMember.data.bio[0].text && 
                      <p className={`${td}__bio`}>
                      {/* {this.props.teamMember.data.bio[0].text} */}
                      {this.props.teamMember.data.bio.map(function(p, i) {
                            return <p key={i}>{p.text}</p>
                          })}
                      </p>}
                    </div>
                  </div>
                </div>
              </section>
            )}
          </div>
        )}
      </ReactCSSTransitionGroup>
    )
  }
}

const mapStateToProps = state => {
  return {
    teamMember: state.teamMember
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchPageData: memberPath => dispatch(teamMemberFetchData(memberPath))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamDetail)
