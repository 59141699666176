import React, { Component } from 'react'

import WOW from 'wow.js/dist/wow.js'

const ns = 'founders'

class Founders extends Component {
  comonentDidMount() {
    new WOW({
      offset: 250,
      mobile: false
    }).init()
  }
  render() {
    const { title, copy, list } = this.props

    return (
      <section className={`${ns}`}>
        <img
          className={`${ns}__triangle wow fadeInLeft`}
          src="https://prismic-io.s3.amazonaws.com/alliance-consumer-growth%2F528b0e0c-5655-4e93-ae1d-ca490bfa6a7e_dotty-triangle.png"
          alt="Decorative Triangles"
          data-wow-duration="1.5s"
        />
        <div className="grid-container wow fadeInUp" data-wow-duration="1.5s">
          <h2 className={`${ns}__headline `}>{title ? title : null}</h2>
          <p className={`${ns}__description`}>{copy}</p>
          {!!list && (
            <ul className={`${ns}__list`}>
              {list.map(function(item, i) {
                return (
                  <li className={`${ns}__list--item`} key={i}>
                    <p className={`${ns}__title`}>{item.data.name[0].text}</p>
                    <p className={`${ns}__company`}>{item.data.company[0].text}</p>
                  </li>
                )
              })}
            </ul>
          )}
        </div>
      </section>
    )
  }
}

export default Founders
