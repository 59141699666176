const errorResponses = [
  'please enter a value',
  'too many recent signup',
  'already subscribed',
  'email address is invalid',
  'email address must contain',
  'email address looks fake',
]

const successResponses = [
  'thank you for subscribing',
]

export const responses = [
  ...errorResponses,
  ...successResponses,
]

/*

// empty string submitted
{
  data: {
    msg: "0 - Please enter a value",
    result: "error"
  },
  status: 200
}

// too many recent requests
{
  data: {
    msg: "Recipient "dnelson@weareenvoy.com" has too many recent signup requests",
    result: "error"
  },
  status: 200
}

// success
{
  data: {
    msg: "Thank you for subscribing!",
    result: "success"
  },
  status: 200
}

// already subscribed
{
  data: {
    msg: "anthony@weareenvoy.com is already subscribed to list Testing Sandbox. <a href="https://weareenvoy.us19.list-manage.com/subscribe/send-email?e=YW50aG9ueUB3ZWFyZWVudm95LmNvbQ%3D%3D&u=7a9a0bfd7aa1c084379e179bb&id=5efa4102af">Click here to update your profile</a>",
    result: "error"
  },
  status: 200
}

// invalid email
{
  data: {
    msg: "0 - The username portion of the email address is invalid (the portion before the @: dnelson test)",
    result: "error"
  },
  status: 200
}

*/
