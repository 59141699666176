import React, { Component } from 'react'

// Prsimic
import Prismic from 'prismic-javascript'
import { PRISMIC_API_URL } from '../../config'

import WOW from 'wow.js/dist/wow.js'

// Components
import Hero from '../../Components/Hero'
import Loader from '../../Components/Loader'
import About from '../../Components/About'
import Categories from '../../Components/Categories'
import NewsHome from '../../Components/NewsHome'

// Slide
import Slide from 'react-slick'

const ns = 'page-home'

const settings = {
  dots: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 5,
  autoplay: true,
  fade: true,
  arrows: false,
  speed: 1500,
  autoplaySpeed: 4000,
  cssEase: 'linear'
}

const settings2 = {
  customPaging: function(i) {
    return (
      <a href="/">
        <div className="slick-custom-pagination" />
      </a>
    )
  },
  dots: true,
  dotsClass: 'slick-dots slick-thumb',
  infinite: false,
  speed: 500,
  arrows: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerMode: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        centerPadding: '0px'
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        centerPadding: '0px'
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerPadding: '0px'
      }
    }
  ]
}

class HomePage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      doc: null,
      loading: true,
      slider: [],
      category: ''
    }
  }

  componentDidMount() {
    // this.props.fetchPageData()
    Prismic.getApi(PRISMIC_API_URL)
      .then(api => {
        return api.query(Prismic.Predicates.at('document.type', 'home_page'), {})
      })
      .then(response => {
        if (response) {
          // console.log(response.results[0])
          this.setState({
            doc: response.results[0],
            slider: [response.results[0].data.header_slider],
            category: ''
          })

          this.animationTimeout()
        }
        // console.log(this.state.slider)
      })

    new WOW({
      offset: 0
    }).init()
  }

  animationTimeout() {
    setTimeout(() => {
      this.setState({
        loading: false
      })
      window.scrollTo(0, 0)
    }, 250)
  }

  handleCategory(category) {
    this.setState({
      category
    })
  }

  render() {
    return (
      <div>
        {this.state.loading ? (
          <Loader />
        ) : (
          <div className={`${ns} page-wrapper`}>
            {!!this.state.doc && (
              <div>
                <div className={`${ns}__header-wrapper`}>
                  <section className="home-slider">
                    <Slide {...settings}>
                      {this.state.slider[0].map(function(slide, i) {
                        return (
                          <div className="home-slider__image-container" key={i}>
                            <img src={slide.slide_image.url} alt={slide.slide_image.alt} className="wow fadeIn" data-wow-duration="2s" />
                          </div>
                        )
                      })}
                    </Slide>
                  </section>
                  <Hero
                    modifier="home"
                    headline={this.state.doc.data.headline_fields[0].headline[0].text}
                    copy={this.state.doc.data.copy_group[0].copy[0].text}
                    link="/partner-brands"
                    link_title={this.state.doc.data.link_titles[0].link_title[0].text}
                    className="home-hero"
                  />
                </div>

                <About 
                  ns={ns}
                  headline={this.state.doc.data.headline_fields[1].headline[0].text}
                  copy={this.state.doc.data.copy_group[1].copy[0].text}
                  link={this.state.doc.data.link_titles[1].link_title[0].text}
                />

                <Categories
                  ns={ns}
                  headline={this.state.doc.data.headline_fields[2].headline[0].text}
                  list={this.state.doc.data.category_list}
                  settings={settings2}
                />

                {/* Decorative Triangle */}
                <img
                  className={`${ns}__triangle wow fadeInLeft`}
                  src="https://prismic-io.s3.amazonaws.com/alliance-consumer-growth%2F2518cd23-9c0e-4066-a413-a6ba163e960d_full-triangle2x.png"
                  alt="Decorative Triangles"
                  data-wow-duration="1.5s"
                />

                <div className="white-section" />

                <NewsHome 
                  ns={ns}
                  headline={this.state.doc.data.headline_fields[3].headline[0].text}
                  list={this.state.doc.data.news}
                  settings={settings2}
                />
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
}

export default HomePage
