import React, { Component } from 'react'
import { connect } from 'react-redux'

// Components
import Hero from '../../Components/Hero'
import Locations from '../../Components/Locations'
import Loader from '../../Components/Loader'

// Actions
import { contactPageFetchData } from '../../store/actions/contactpage'

// Prsimic
import Prismic from 'prismic-javascript'
import { PRISMIC_API_URL } from '../../config'

// Wowjs
import WOW from 'wow.js/dist/wow.js'

const ns = 'page-contact'

class Contact extends Component {
  constructor(props) {
    super(props)

    this.state = {
      doc: null,
      loading: true
    }
  }
  componentDidMount() {
    this.props.fetchPageData()
    Prismic.getApi(PRISMIC_API_URL)
      .then(api => {
        return api.query(Prismic.Predicates.at('document.type', 'contact_page'), {})
      })
      .then(response => {
        if (response) {
          // console.log(response.results[0])
          this.setState({
            doc: response.results[0]
          })
        }
      })

    new WOW({
      offset: 150,
      mobile: false
    }).init()
  }

  componentDidUpdate(prevProps) {
    if (this.props.contactpage !== prevProps.contactpage) {
      this.animationTimeout()
    }
  }

  // For Page Reload to init WOWjs again
  componentWillReceiveProps(props) {
    if (this.props.contactpage !== props.contactpage) {
      this.animationTimeout()
    }
  }

  animationTimeout() {
    setTimeout(() => {
      this.setState({
        loading: false
      })
      window.scrollTo(0, 0)
    }, 500)
  }

  render() {
    return (
      <div>
        {this.state.loading ? (
          <Loader />
        ) : (
          <div className={`${ns}`}>
            {!!this.props.contactpage.data && (
              <div className="page-wrapper">
                <img
                  className={`${ns}__triangle2 wow fadeInDown`}
                  src="https://prismic-io.s3.amazonaws.com/alliance-consumer-growth%2F63fb4f25-0c56-4a26-b862-f521c61e8002_half-triangle.png"
                  alt="Decorative Triangles"
                  data-wow-duration="1.5s"
                />
                <Hero
                  headline={this.props.contactpage.data.headline[0].text}
                  eyebrow={this.props.contactpage.data.eyebrow[0].text}
                  inquires={this.props.contactpage.data.inquires}
                  modifier="contact"
                />
                <Locations locations={this.props.contactpage.data.location_list} />
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    contactpage: state.contactpage
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchPageData: () => dispatch(contactPageFetchData())
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Contact)
