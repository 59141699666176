import axios from 'axios'
import jsonpAdapter from 'axios-jsonp'

import { mailchimpConfig } from '../common/config'
import { messenger } from './mixins/messenger'
import * as msgs from './mixins/messages'

let instance

class Mailchimp {
	constructor(props) {
		if (instance) {
			return instance
		}

		Object.assign(this, msgs)

		instance = this
		return instance
	}

	subscribeAnAddress(emailAddress, callback) {
		const {
			formActionUri
		} = mailchimpConfig

		const requestUrl = `${formActionUri}${emailAddress}`

		axios({
			adapter: jsonpAdapter,
			callbackParamName: 'c',
			method: 'post',
			url: requestUrl,
		})
		.then((response) => {
			if (callback) {
				// messenger used to respond with more human-friendly message
				// than what is returned by default from Mailchimp
				callback(messenger(response, this))
			}
		})
		.catch((error) => {
			if (callback) {
				const {
					msgRequestError
				} = this

				// no need for messenger, just respond with request error
				const responseMessage = {
					message: msgRequestError,
					error: true
				}

				callback(responseMessage)
			}
		})
	}
}

const mailchimp = new Mailchimp()
Object.assign(mailchimp, messenger)

export default mailchimp
