export function partnerpage(state = [], action) {
  switch (action.type) {
    case 'PARTNERPAGE_FETCH_DATA_SUCCESS':
      return action.page
    default:
      return state
  }
}

export function partners(state = [], action) {
  switch (action.type) {
    case 'PARTNERS_FETCH_DATA_SUCCESS':
      return action.partners
    default:
      return state
  }
}

export function partnerDetail(state = [], action) {
  switch (action.type) {
    case 'PARTNERDETAIL_FETCH_DATA_SUCCESS':
      return action.partnerDetail
    default:
      return state
  }
}

export function partnerLinkedArticles(state = [], action) {
  switch (action.type) {
    case 'LINKEDARTICLES_FETCH_DATA_SUCCESS':
      return action.linkedArticles
    default:
      return state
  }
}
