import React, { Component } from 'react'

const ns = 'disclosure'

class Disclosure extends Component {
  componentDidMount() {
    console.log('did mount!');
    window.scrollTo(0, 0)
  }

  render() {
    return(
      <section className={ns}>
        <div className={`${ns}__sectionContainer`}>
          <div className={`${ns}__title`}>
            Important Disclosures
          </div>

          <div className={`${ns}__paragraphContainer`}>
            <p className={`${ns}__paragraph`}>
              This website has been prepared regarding Alliance Consumer Growth, LLC (“ACG”) for informational purposes only. While the information contained herein is believed to be accurate, it does not purport to be all-inclusive. ACG expressly disclaims any and all liability and makes no representations or warranties, expressed or implied, as to the accuracy or compelteness of this website or any third-party content included herein. Publication of this website and the statements included herein should not be construed as a solicitation, offer or recommendation to buy or sell a security or other investment product. All website content is subject to change without notice and should not be interpreted as personalized investment advice or relied upon to make an investment decision.
            </p>
            <p className={`${ns}__paragraph`}>
              All statements and opinions included on this website are subject to change as economic and market conditions dictate, and do not necessarily represent the views of ACG or its affiliates. Past performance may not be indicative of future results and there can be no assurance that any views, outlooks, projections or forward-looking statements will come to pass. Any description of an investment is provided solely to illustrate the firm’s investment ethos or investment strategy and may not be representative of all investments. Investments may be excluded on the basis of confidentiality or other contractual restrictions. Investing involves risk, including the potential loss of principal, and the profitability of any particular investment strategy cannot be guaranteed.
            </p>
            <p className={`${ns}__paragraph`}>
              Awards: GrowthCap Top Consumer Growth Equity Firm in America based on a combination of fund performance, consistency of returns, capital raised, investment strategy and partner experience and determined via survey by GrowthCap Partners, LLC of 25 advisers. Fortune magazine’s “40 under 40” awarded to indivudals that have been nominated and selected by fortune reporters, writers, and editors. Forbes 25 most influential “Kingmakers & Catalysts” runs nominees through its internal data science platform to surface the top performing brands as finalists and additional candidates; thereafter, the narrowed list of finalists are submitted to judges who vote to determine the award recipients. Ratings may not be representative and are not indicative of future performance.
            </p>
            <p className={`${ns}__paragraph`}>
              ACG is an sec registered investment adviser that maintains a principal place of business in New York. The firm may only transact business in those states in which it is notice filed or qualifies for a corresponding exemption from such requirements. For information about the firm’s registration status and business operations, please consult the firm’s form adv disclosure documents, the most recent versions of which are available on the sec’s investment adviser public disclosure website at www.adviserinfo.sec.gov.
            </p>
          </div>
        </div>

        <div className={`${ns}__sectionContainer`}>
          <div className={`${ns}__title`}>
            Terms of Use
          </div>

          <div className={`${ns}__paragraphContainer`}>
            <p className={`${ns}__paragraph`}>
            By using this website and any site associated with or control by Alliance Consumer Growth, LLC (“ACG”), including but not limited to blogs and social media sites (collectively, “website”), viewers agree to be bound by the terms of use and disclaimers set forth below, which may be modified at any time prior without prior notice.

            </p>
            <p className={`${ns}__paragraph`}>
              This website is for informational purposes only and no statement is to be construed as an offer to sell or a solicitation to buy any security, investment product or service.
              ACG does not intend for the information on the website to be investment advice and should not be relied upon to make an investment decision. Investing involves risk, including the potential loss of principal, and past performance may not be indicative of future results.
            </p>
            <p className={`${ns}__paragraph`}>
              Content and material posted on the website are the property of acg and may not be distributed or disseminated without prior written consent. The website is not intended for distribution to, or use by, any person or entity in any jurisdiction or country where such distribution would be contrary to law or regulation or which would subject ACG, its employees, directors, officers or agents to any registration within such jurisdiction or country.
            </p>
            <p className={`${ns}__paragraph`}>
              The content on the website is provided “as is” without any express or implied warranties. ACG does not assume any obligation to update the information and data on the website and does not guarantee that it is accurate, currently, valid, complete or suitable for any purpose. ACG makes no representations concerning the website, or to any website linked hereto, and disclaims all express, implied and statutory warranties of any kind to the extent allowed by applicable law. In no event will ACG or any of its directors, officers, employees or other representatives be liable or have any responsibility of any kind for any direct,indirectly, special, consequential, multiple, punitive or other damages arising from information on the website or the use thereof.
            </p>
          </div>
        </div>
      </section>
    )
  }
}

export default Disclosure;