export const handelize = (text) => {
	let formattedText = text

	// lower case
	formattedText = formattedText.toLowerCase()

	// replace ' ' with '-'
	formattedText = formattedText.replace(/\s/gi, '-')

	// change &s
	formattedText = formattedText.replace(/&/gi, 'and')

	return formattedText
}

export const getQueryStringValue = (queryName) => {
  const query = window.location.search.substring(1)
  const vars = query.split('&')

  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split('=')

    if (pair[0] === queryName) {
      return(pair[1])
    }
  }

  return false
}