import React, { Component } from 'react'
import { connect } from 'react-redux'

// Components
import Hero from '../../Components/Hero'
import CopyBlock from '../../Components/CopyBlock'
import TeamList from '../../Components/TeamList'
import Founders from '../../Components/Founders'
import Loader from '../../Components/Loader'

// Actions
import { teamPageFetchData, teamFetchData, foundersFetchData } from '../../store/actions/teampage'

const ns = 'page-team'

class Team extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true
    }
  }
  componentDidMount() {
    this.props.fetchPageData()
    this.props.fetchTeamData()
    this.props.fetchFoundersData()
  }

  componentDidUpdate(prevProps) {
    if (this.props.teampage !== prevProps.teampage) {
      this.animationTimeout()
    }
  }

  // For Page Reload to init WOWjs again
  componentWillReceiveProps(props) {
    if (this.props.teampage !== props.teampage) {
      this.animationTimeout()
    }
  }

  animationTimeout() {
    setTimeout(() => {
      this.setState({
        loading: false
      })
      window.scrollTo(0, 0)
    }, 500)
  }

  render() {
    return (
      <div>
        {this.state.loading ? (
          <Loader />
        ) : (
          <div className={`${ns}`}>
            {!!this.props.teampage.data && (
              <div className="page-wrapper">
                <Hero
                  headline={this.props.teampage.data.headline[0].text}
                  alt={this.props.teampage.data.hero_background_image.alt}
                  eyebrow={this.props.teampage.data.eyebrow[0].text}
                  bgImage={this.props.teampage.data.hero_background_image.url}
                  modifier="white"
                />
                <CopyBlock 
                copy={this.props.teampage.data.quote[0].text}
                eyebrow="Team"
                />
                <TeamList title={this.props.teampage.data.team_headline[0].text} list={this.props.team} />
                <Founders
                  title={this.props.teampage.data.founders_headline[0].text}
                  copy={this.props.teampage.data.founders_copy[0].text}
                  list={this.props.founders}
                />
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    teampage: state.teampage,
    team: state.team,
    founders: state.founders
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchPageData: () => dispatch(teamPageFetchData()),
    fetchTeamData: () => dispatch(teamFetchData()),
    fetchFoundersData: () => dispatch(foundersFetchData())
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Team)
