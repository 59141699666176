// Prsimic
import Prismic from 'prismic-javascript'
import { PRISMIC_API_URL } from '../../config'

export function partnerPageFetchDataSuccess(page) {
  return {
    type: 'PARTNERPAGE_FETCH_DATA_SUCCESS',
    page
  }
}

export function partnerPageFetchData() {
  return dispatch => {
    Prismic.getApi(PRISMIC_API_URL)
      .then(api => {
        return api.query(Prismic.Predicates.at('document.type', 'brand_partners_page'), {})
      })
      .then(response => {
        if (response) {
          dispatch(partnerPageFetchDataSuccess(response.results[0]))
        }
      })
  }
}

export function partnersFetchDataSuccess(partners) {
  return {
    type: 'PARTNERS_FETCH_DATA_SUCCESS',
    partners
  }
}

export function partnersFetchData() {
  return dispatch => {
    Prismic.getApi(PRISMIC_API_URL)
      .then(api => {
        return api.query(Prismic.Predicates.at('document.type', 'brand_partner'), {
          orderings: '[my.brand_partner.order]',
          pageSize: 50
        })
      })
      .then(response => {
        if (response) {
          dispatch(partnersFetchDataSuccess(response.results))
        }
      })
  }
}

export function partnerDetailFetchDataSuccess(partnerDetail) {
  return {
    type: 'PARTNERDETAIL_FETCH_DATA_SUCCESS',
    partnerDetail
  }
}

export function partnerDetailFetchData(partnerPath) {
  return dispatch => {
    Prismic.getApi(PRISMIC_API_URL)
    .then(api => {
      return api.query(Prismic.Predicates.at('my.brand_partner.uid', partnerPath), {
        pageSize: 500
      })
    })
    .then(response => {
        if (response) {
          // console.log(response.results[0])
          dispatch(partnerDetailFetchDataSuccess(response.results[0]))
        }
      })
  }
}

export function fetchLinkedArticlesSuccess(linkedArticles) {
  return {
    type: 'LINKEDARTICLES_FETCH_DATA_SUCCESS',
    linkedArticles
  }
}

export function fetchLinkedArticles(partnerPath) {
  return dispatch => {
    Prismic.getApi(PRISMIC_API_URL)
      .then(api => {
        return api.query(Prismic.Predicates.at('document.type', 'news_article'), {
          pageSize: 500
        })
      })
      .then(response => {
        if (response) {
          const linkedArticles = []
          response.results.map((article, i) => {
            let articleSlug = article.data.brand_partner_link.slug
            if (articleSlug === partnerPath) {
              return linkedArticles.push(article)
            } else return null
          })
          dispatch(fetchLinkedArticlesSuccess(linkedArticles))
        }
      })
  }
}
