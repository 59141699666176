// Prsimic
import Prismic from 'prismic-javascript'
import { PRISMIC_API_URL } from '../../config';

export function teamPageFetchDataSuccess(page) {
    return {
        type: 'TEAMPAGE_FETCH_DATA_SUCCESS',
        page
    };
}

export function teamPageFetchData() {
    return (dispatch) => {
        Prismic.getApi(PRISMIC_API_URL)
          .then(api => {
            return api.query(Prismic.Predicates.at('document.type', 'team_page'), {})
          })
          .then(response => {
            if (response) {
              dispatch(teamPageFetchDataSuccess(response.results[0]))
            }
          })
    };
}

export function teamFetchDataSuccess(team) {
    return {
        type: 'TEAM_FETCH_DATA_SUCCESS',
        team
    };
}

export function teamFetchData() {
    return (dispatch) => {
        Prismic.getApi(PRISMIC_API_URL)
          .then(api => {
            return api.query(Prismic.Predicates.at('document.type', 'team_members'), {
                orderings: '[my.team_members.order]'
            })
          })
          .then(response => {
            if (response) {
              dispatch(teamFetchDataSuccess(response.results))
            }
          })
    };
}

export function foundersFetchDataSuccess(founders) {
    return {
        type: 'FOUNDERS_FETCH_DATA_SUCCESS',
        founders
    };
}

export function foundersFetchData() {
    return (dispatch) => {
        Prismic.getApi(PRISMIC_API_URL)
          .then(api => {
            return api.query(Prismic.Predicates.at('document.type', 'founding_member'), {
                orderings: '[my.founding_member.order]'
            })
          })
          .then(response => {
            if (response) {
                // console.log(response)
              dispatch(foundersFetchDataSuccess(response.results))
            }
          })
    };
}

export function teamMemberFetchDataSuccess(teamMember) {
    return {
        type: 'TEAMMEMBER_FETCH_DATA_SUCCESS',
        teamMember
    };
}

export function teamMemberFetchData(memberPath) {
    return (dispatch) => {
        Prismic.getApi(PRISMIC_API_URL).then(api => {
          api.query(Prismic.Predicates.at('my.team_members.uid', memberPath)).then(response => {
            if (response) {
              dispatch(teamMemberFetchDataSuccess(response.results[0]))
            }
          })
        })
    };
}

