import { createStore, applyMiddleware } from 'redux'
import { syncHistoryWithStore } from 'react-router-redux'
import reduxThunk from 'redux-thunk'
import { createBrowserHistory } from 'history'
import rootReducer from './reducers';

const defaultState = {}

const store = createStore(rootReducer, defaultState, applyMiddleware(reduxThunk))

export const history = syncHistoryWithStore(createBrowserHistory(), store)

export default store
