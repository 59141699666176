import React, { Component } from 'react'
import { Link } from 'react-router-dom'

const ns = 'header'

const navList = [
  {
    url: '/partner-brands',
    title: 'Partner Brands',
    isExternal : false
  },
  {
    url: '/about',
    title: 'About',
    isExternal : false
  },
  {
    url: '/team',
    title: 'Team',
    isExternal : false
  },
  {
    url: '/news',
    title: 'News',
    isExternal : false
  },
  {
    url: '//jobs.acgpartners.com/',
    title: 'Careers',
    isExternal : true
  },
  {
    url: '/contact',
    title: 'Contact',
    isExternal : false
  }
]

class Navbar extends Component {
  constructor(props) {
    super(props)

    this.state = {
      header: null,
      headerHeight: null
    }
  }
  componentDidMount() {
    this.setState({
      header: document.querySelector('[data-header="header"]'),
      headerHeight: document.querySelector('[data-header="header"]').offsetHeight,
      sideNav: false
    })
    window.addEventListener('scroll', () => {
      this.stickyNavBar()
    })
  }

  stickyNavBar() {
    if (window.pageYOffset > this.state.headerHeight) {
      //set header to position fixed and pull above screen
      document.documentElement.classList.add('menu--pull-up')
      //this.state.header.style.cssText = `transform: translate3d(0, -${this.state.headerHeight + 30}px, 0)`

      let pageOffset

      if (this.props.location === '/') {
        pageOffset = 200
      } else {
        pageOffset = 200
      }
      // animate header down when end of hero is reached
      if (window.pageYOffset >= pageOffset - this.state.headerHeight) {
        this.state.header.classList.add('transition')
        document.documentElement.classList.add('menu--open')
      }
    }

    // Set header back to absolute when scrolled to top
    if (window.pageYOffset === 0) {
      document.documentElement.classList.remove('menu--pull-up')
      document.documentElement.classList.remove('menu--open')

      let header = this.state.header

      header.classList.remove('transition')
      header.style.cssText = ''
    }
  }

  handleSideNav() {
    this.setState(function(prevState) {
      return { sideNav: !prevState.sideNav }
    })

    if (!this.state.sideNav) {
      document.body.classList.add('mobile-menu-open')
    } else {
      document.body.classList.remove('mobile-menu-open')
    }
  }

  closeMenu() {
    this.setState(function(prevState) {
      return { sideNav: !prevState.sideNav }
    })
  }

  render() {
    let sideMenuButtonClass = this.state.sideNav ? ns + '__menu--mobile-button active' : ns + '__menu--mobile-button'
    let overlayClass = this.state.sideNav ? ns + '__menu--mobile-overlay open' : ns + '__menu--mobile-overlay'

    const nsHeader = this.props.location === '/' ? ns + ' header--transparent header--index' : ns

    return (
      <nav className={nsHeader} role="navigation" data-header="header">
        <div className={`${ns}__container grid-container`}>
          <div className={`${ns}__logo`}>
            <Link to="/">
              <img
                className={`${ns}__logo--image ${ns}__logo--main`}
                src="https://prismic-io.s3.amazonaws.com/alliance-consumer-growth%2F684dc5c4-1cf2-4419-9884-12f41a126eaa_secondary-still.svg"
                alt="Logo"
              />
              {/* <img
                className={`${ns}__logo--image ${ns}__logo--scroll`}
                src="https://prismic-io.s3.amazonaws.com/alliance-consumer-growth%2F8333334d-0a4b-4ae2-bfa0-0a1f14d33f36_logo-scroll-main.svg"
                alt="Logo"
              /> */}
              <img
                className={`${ns}__logo--image ${ns}__logo--scroll`}
                src="https://prismic-io.s3.amazonaws.com/alliance-consumer-growth%2F684dc5c4-1cf2-4419-9884-12f41a126eaa_secondary-still.svg"
                alt="Logo"
              />
            </Link>
          </div>
          <div className={`${ns}__menu`}>
            <ul className={`${ns}__menu--desktop`}>
              {navList.map((item, index) => {
                let menuClass = this.props.location === item.url ? ns + '__menu--item active' : ns + '__menu--item'

                const internalLink =  <Link className={`${ns}__link`} to={item.url}>
                                        {item.title}
                                      </Link>

                const externalLink =  <a className={`${ns}__link`} href={item.url} target="_blank">
                                        {item.title}
                                      </a>

                let theLink = internalLink;

                if(item.isExternal){
                  theLink = externalLink;
                }

                return (
                  <li className={menuClass} key={index}>
                    { theLink }
                  </li>
                )
              })}
            </ul>

            <div className={`${ns}__menu--mobile-wrapper`}>
              <div className={sideMenuButtonClass} onClick={this.handleSideNav.bind(this)}>
                <span className={`${ns}__menu--mobile-button-top`} />
                <span className={`${ns}__menu--mobile-button-middle`} />
                <span className={`${ns}__menu--mobile-button-bottom`} />
              </div>

              <div className={overlayClass}>
                <nav className={`${ns}__menu--mobile-overlay-menu`}>
                  {this.state.sideNav ? (
                    <ul className={`${ns}__menu--mobile-list`}>
                      {navList.map((item, index) => {
                        let sideMenuClass = this.state.sideNav ? ns + '__menu--mobile-item active' : ns + '__menu--mobile-item'

                        const internalLink =  <Link className={`${ns}__link`} to={item.url} onClick={() => this.handleSideNav()}>
                          {item.title}
                        </Link>

                        const externalLink =  <a className={`${ns}__link`} href={item.url} target="_blank">
                          {item.title}
                        </a>

                        let theLink = internalLink;

                        if(item.isExternal){
                          theLink = externalLink;
                        }

                        return (
                          <li className={sideMenuClass} key={index}>
                            { theLink }
                          </li>
                        )
                      })}
                    </ul>
                  ) : (
                    ''
                  )}
                </nav>
              </div>
            </div>
          </div>
        </div>
      </nav>
    )
  }
}

export default Navbar
