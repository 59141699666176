// Prsimic
import Prismic from 'prismic-javascript'
import { PRISMIC_API_URL } from '../../config'

export function fetchArticlesSuccess(articles) {
  return {
    type: 'NEWSPAGE_FETCH_DATA_SUCCESS',
    articles
  }
}

export function newsPagefetchArticles() {
  return dispatch => {
    Prismic.getApi(PRISMIC_API_URL)
      .then(api => {
        return api.query(Prismic.Predicates.at('document.type', 'news_article'), {
          orderings: '[my.news_article.publication_date desc]',
          pageSize : 300
        })
      })
      .then(response => {
        if (response) {
          // console.log(response)
          dispatch(fetchArticlesSuccess(response.results))
        }
      })
  }
}
