import { responses } from './responses'

const successCode = 200

export const messenger = (response, instance) => {
	let message = ''
	let error = false
	let responseMessage = {}

	// for troubleshooting
	// console.log('response')
	// console.dir(response)

	const {
		data,
		status,
	} = response

	const {
		msg,
	} = data

	const {
		msgAlreadySubscribed,
		msgEmailInvalid,
		msgEmptyField,
		msgSuccess,
		msgTooManyRecentRequests,
	} = instance

	const formattedMsg = msg.toLowerCase()

	if (status === successCode) {
		responses.forEach((msg, index) => {
			if (formattedMsg === 'this email cannot be added to this list. please enter a different email address.') {
				message = msgEmailInvalid
				error = true
			}

			if (formattedMsg.includes('too many subscribe attempts for this email address. please try again in about 5 minutes.')) {
				message = msgTooManyRecentRequests
				error = true
			}

			if (formattedMsg.includes(responses[index])) {
				// indexes coordinate with responses
				// loosely coupled to ease maintenance
				if (index === 0) {
					message = msgEmptyField
					error = true
				}

				if (index === 1) {
					message = msgTooManyRecentRequests
					error = true
				}

				// already subscribed is an error, but UX wise
				// shouldn't come off with negative connotation
				if (index === 2) {
					message = msgAlreadySubscribed
					error = false
				}

				if (index === 3) {
					message = msgEmailInvalid
					error = true
				}

				if (index === 4) {
					message = msgEmailInvalid
					error = true
				}

				if (index === 5) {
					message = msgEmailInvalid
					error = true
				}

				if (index === 6) {
					message = msgSuccess
					error = false
				}
			}
		})
	}

	responseMessage.message = message
	responseMessage.error = error

	return responseMessage
}
