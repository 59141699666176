import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import WOW from 'wow.js/dist/wow.js'

const ns = 'hero'

class Hero extends Component {
  comonentDidMount() {
    new WOW({
      offset: 0
    }).init()
  }
  componentDidUpdate() {
    new WOW({
      offset: 0
    }).init()
  }
  render() {
    const customClass = this.props.modifier ? ns + ' ' + ns + '--' + this.props.modifier : ns
    const transitionClass = window.location.pathname === '/' ? 'wow fadeInRight' : 'wow fadeInRight'
    const { bgImage, category, headline, copy, link, link_title, inquires, link_arrow, link_arrow_title } = this.props
    const transitionDelay = window.location.pathname === '/' ? '0.7s' : ''

    return (
      <section className={`${customClass} `}>
        {bgImage && <img className={`${ns}__bg-image`} alt={this.props.alt ? this.props.alt : ""} src={bgImage} />}
        <div className={`grid-container ${transitionClass} `} data-wow-duration="1.5s" data-wow-delay={transitionDelay}>
          {link_arrow &&
            link_arrow_title && (
              <div className={`${ns}__back`}>
                <Link to={`/${this.props.link_arrow}`}>
                  <svg width='50' height='7' viewBox='0 0 50 7' xmlns='http://www.w3.org/2000/svg'>
                      <g id='Mobile' fill='none' fillRule='evenodd'>
                          <g id='partner-brands-detail-mobile' transform='translate(-20 -101)' fill='#FFF'>
                              <g id='hero' transform='translate(0 70)'>
                                  <g id='cta' transform='translate(19 23)'>
                                      <path d='M5.52478571,12 L5.52478571,14.5646804 L0.984375,11.5977318 L5.52478571,8.63078318 L5.52478571,11 L51,11 L51,12 L5.52478571,12 Z'
                                      id='arrow' />
                                  </g>
                              </g>
                          </g>
                      </g>
                  </svg>
                </Link>
                <Link to={`/${this.props.link_arrow}`} className={`${ns}__link--arrow`}>
                  {this.props.link_arrow_title}
                </Link>
              </div>
            )}
          {/* {eyebrow && <p className={`${ns}__eyebrow`}>{eyebrow}</p>} */}
          {category && <p className={`${ns}__category`}>{category}</p>}
          {headline && <h1 className={`${ns}__headline`} dangerouslySetInnerHTML={{ __html: headline }} />}
          {copy && <p className={`${ns}__copy`}>{this.props.copy}</p>}
          {category && <p className={`${ns}__category`}>{this.props.category}</p>}
          {link &&
            link_title && (
              <a href={this.props.link} className={`${ns}__main-link`}>
                {this.props.link_title}
              </a>
            )}
          {inquires && (
            <ul className={`${ns}__list`}>
              {inquires.map(function(item, i) {
                return (
                  <li key={i} className={`${ns}__list--item`}>
                    <p className={`${ns}__title`}>{item.title[0].text}</p>
                    <a className={`${ns}__link`} href={`mailto:${item.email[0].text}`}>
                      {item.email[0].text}
                    </a>
                  </li>
                )
              })}
            </ul>
          )}
        </div>
      </section>
    )
  }
}

export default Hero
