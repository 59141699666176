import React from 'react'

// Slide
import Slide from 'react-slick'
import { handelize } from '../../utils'

const Categories = (props) => {
  const ns = props.ns

  return (
    <section className={`${ns}__focus-background`}>
    <div className={`grid-container`}>
      <div className={`${ns}__focus-wrapper wow fadeInUp`} data-wow-duration="1.5s">
        <h1 className={`${ns}__headline-3 ${ns}__headline-3-focus`}>{props.headline}</h1>
        <ul className={`${ns}__focus-list`}>
          {props.list.map((listItem, index) => {
            let titleHandelized = handelize(listItem.category_title[0].text)

            return (
              <a
                href={
                  listItem.category_title[0].text.toLowerCase() === 'all'
                    ? '/partner-brands'
                    : `${listItem.category_link[0].text}?focus=${titleHandelized}`
                }
                key={index}
              >
                <li className={`${ns}__focus-list-item`}>
                  <div className={`${ns}__focus--bg`} style={{ backgroundImage: `url(${listItem.category_image.url})` }} />
                  <span className={`${ns}__focus-list-title`}>{listItem.category_title[0].text}</span>
                </li>
              </a>
            )
          })}
        </ul>

        <div className={`${ns}--controls`}>
          <Slide {...props.settings}>
            {props.list.map((listItem, index) => {
              let titleHandelized = handelize(listItem.category_title[0].text)

              return (
                <div className={`${ns}--controls-button`} key={index}>
                  <a
                    href={
                      listItem.category_title[0].text.toLowerCase() === 'all'
                        ? '/partner-brands'
                        : `${listItem.category_link[0].text}?focus=${titleHandelized}`
                    }
                    key={index}
                  >
                    <li className={`${ns}__focus-list2-item2`}>
                      <div className={`${ns}__focus-list2-item2`} style={{ backgroundImage: `url(${listItem.category_image.url})` }}>
                        <span className={`${ns}__focus-list2-title2`}>{listItem.category_title[0].text}</span>
                      </div>
                    </li>
                  </a>
                </div>
              )
            })}
          </Slide>
        </div>
      </div>
    </div>
  </section>
  )
}

export default Categories