import React, { Component } from 'react'
import { connect } from 'react-redux'

// Components
import Hero from '../../Components/Hero'
import PartnerList from '../../Components/ParnterList'
import Loader from '../../Components/Loader'

// Actions
import { partnerPageFetchData, partnersFetchData } from '../../store/actions/partnerpage'

const ns = 'page-partners'

class Partners extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true
    }
  }
  componentDidMount() {
    this.props.fetchPageData()
    this.props.fetchPartnerData()
  }

  componentDidUpdate(prevProps) {
    if (this.props.partnerpage !== prevProps.partnerpage) {
      this.animationTimeout()
    }
  }

  // For Page Reload to init WOWjs again
  componentWillReceiveProps(props) {
    if (this.props.partnerpage !== props.partnerpage) {
      this.animationTimeout()
    }
  }

  animationTimeout() {
    setTimeout(() => {
      this.setState({
        loading: false
      })
      window.scrollTo(0, 0)
    }, 500)
  }

  render() {
    return (
      <div>
        {this.state.loading ? (
          <Loader />
        ) : (
          <div className={`${ns}`}>
            {!!this.props.partnerpage.data && (
              <div className="page-wrapper">
                <Hero
                  headline={this.props.partnerpage.data.headline[0].text}
                  eyebrow={this.props.partnerpage.data.eyebrow[0].text}
                  bgImage={this.props.partnerpage.data.image.url}
                  modifier="small"
                />
                {/* <CopyBlock copy={this.props.partnerpage.data.quote[0].text} modifier="blue" /> */}
                <PartnerList list={this.props.partners} />
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    partnerpage: state.partnerpage,
    partners: state.partners
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchPageData: () => dispatch(partnerPageFetchData()),
    fetchPartnerData: () => dispatch(partnersFetchData())
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Partners)
