import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Switch, Route, withRouter } from 'react-router-dom'

// Containers
import HomePage from '../HomePage'
import Partners from '../PartnerPage'
import PartnerDetail from '../PartnerDetailPage'
import About from '../AboutPage'
import Team from '../TeamPage'
import TeamDetail from '../TeamDetailPage'
import News from '../NewsPage'
import Contact from '../ContactPage'
import Disclosure from '../Disclosure'

// Components
import Navbar from '../../Components/Navbar'
import Footer from '../../Components/Footer'

class App extends Component {
  render() {
    return (
      <div className="app">
        <Navbar location={this.props.location.pathname} />
        <main id="main" role="main">
          <Switch>
            <Route path="/" exact component={HomePage} />
            <Route exact path="/partner-brands" component={Partners} />
            <Route exact path="/partner-brands/:id" component={PartnerDetail} />
            <Route exact path="/about" component={About} />
            <Route exact path="/team" component={Team} />
            <Route exact path="/team/:id" component={TeamDetail} />
            <Route exact path="/news" component={News} />
            <Route exact path="/news/:id" component={News} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/disclosures&termsofuse" component={Disclosure} />
          </Switch>
        </main>
        <Footer />
      </div>
    )
  }
}

export default withRouter(connect()(App))
