export function teampage(state = [], action) {
    switch (action.type) {
        case 'TEAMPAGE_FETCH_DATA_SUCCESS':
            return action.page;
        default:
            return state;
    }
}

export function team(state = [], action) {
    switch (action.type) {
        case 'TEAM_FETCH_DATA_SUCCESS':
            return action.team;
        default:
            return state;
    }
}

export function founders(state = [], action) {
    switch (action.type) {
        case 'FOUNDERS_FETCH_DATA_SUCCESS':
            return action.founders;
        default:
            return state;
    }
}

export function teamMember(state = [], action) {
    switch (action.type) {
        case 'TEAMMEMBER_FETCH_DATA_SUCCESS':
            return action.teamMember;
        default:
            return state;
    }
}