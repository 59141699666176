import React, { Component } from 'react'

import WOW from 'wow.js/dist/wow.js'

const ns = 'copy-block'

class CopyBlock extends Component {
  comonentDidMount() {
    new WOW({
      offset: 150,
      mobile: false
    }).init()
  }
  componentDidUpdate() {
    new WOW({
      ffset: 150,
      mobile: false
    }).init()
  }
  render() {
    const customClass = this.props.modifier ? ns + ' ' + ns + '--' + this.props.modifier : ns

    return (
      <section className={`${customClass}`}>
        <img
          className={`${ns}__triangle wow fadeInDown`}
          src="https://prismic-io.s3.amazonaws.com/alliance-consumer-growth%2F63fb4f25-0c56-4a26-b862-f521c61e8002_half-triangle.png"
          alt="Decorative Triangles"
          data-wow-duration="1.5s"
        />
        <div className="grid-container">
          {this.props.eyebrow && (
            <h1 className={`${ns}__copy--eyebrow wow fadeInUp`} data-wow-duration="1.5s">
              {this.props.eyebrow}
            </h1>
          )}
          {this.props.copy && (
            <h3 className={`${ns}__copy wow fadeInUp`} data-wow-duration="1.5s">
              {this.props.copy}
            </h3>
          )}
        </div>
      </section>
    )
  }
}

export default CopyBlock
