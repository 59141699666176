import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'

// Styles
import './styles/main.scss'

import App from './Containers/App'

import store, { history } from './store'

ReactDOM.render(
  <Provider store={store}>
    <Router onUpdate={() => window.scrollTo(0, 0)} history={history}>
      <App />
    </Router>
  </Provider>,
  document.getElementById('root')
)
