import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import WOW from 'wow.js/dist/wow.js'

const ns = 'team-list'

class TeamList extends Component {
  comonentDidMount() {
    new WOW({
      offset: 150,
      mobile: false
    }).init()
  }
  render() {
    const { title, list } = this.props

    // console.log(this.props)

    return (
      <section className={`${ns}`}>
        <div className="grid-container wow fadeInUp" data-wow-duration="1.5s">
          <h2 className={`${ns}__headline`}>{title}</h2>
          {!!list && (
            <ul className={`${ns}__list`}>
              {list.map(function(item, i) {
                let teamLink = '/team/' + item.uid
                return (
                  <li className={`${ns}__list--item`} key={i}>
                    <Link to={teamLink}>
                      <div className ={`${ns}__list--image-wrapper`}>
                        <img src={item.data.image.url} alt={item.data.name[0].text} />
                      </div>
                      <p className={`${ns}__title`}>{item.data.name[0].text}</p>
                    </Link>
                    <p className={`${ns}__position`}>
                    {item.data.position.map(function(p, i) {
                      return <span key={i}>{p.text}<br/></span>
                    })}
                    </p>
                  </li>
                )
              })}
            </ul>
          )}
        </div>
      </section>
    )
  }
}

export default TeamList
