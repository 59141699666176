// Prsimic
import Prismic from 'prismic-javascript'
import { PRISMIC_API_URL } from '../../config';

export function contactPageFetchDataSuccess(page) {
    return {
        type: 'CONTACTPAGE_FETCH_DATA_SUCCESS',
        page
    };
}

export function contactPageFetchData() {
    return (dispatch) => {
        Prismic.getApi(PRISMIC_API_URL)
          .then(api => {
            return api.query(Prismic.Predicates.at('document.type', 'contact_page'), {})
          })
          .then(response => {
            if (response) {
              dispatch(contactPageFetchDataSuccess(response.results[0]))
            }
          })
    };
}