// Prsimic
import Prismic from 'prismic-javascript'
import { PRISMIC_API_URL } from '../../config';

export function aboutPageFetchDataSuccess(page) {
    return {
        type: 'ABOUTPAGE_FETCH_DATA_SUCCESS',
        page
    };
}

export function aboutPageFetchData() {
    return (dispatch) => {
        Prismic.getApi(PRISMIC_API_URL)
          .then(api => {
            return api.query(Prismic.Predicates.at('document.type', 'about_page'), {})
          })
          .then(response => {
            if (response) {
              dispatch(aboutPageFetchDataSuccess(response.results[0]))
            }
          })
    };
}