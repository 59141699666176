import React, { Component } from 'react'

import WOW from 'wow.js/dist/wow.js';

let ns = 'partner-copy-block'

class PartnerCopyBlock extends Component {
  comonentDidMount() {
    new WOW({
        offset: 150,
        mobile: false,
    }).init();
  }
  render() {
    const customClass = this.props.modifier ? ns + ' ' + ns + '--' + this.props.modifier : ns
    const { copy, link, link_title, milestones, team, social_links, year_founded, year_partnered_with_acg } = this.props
    return (
      <section className={`${customClass}`}>
        <div className="grid-container">
          <div className={`${ns}__col`}>
            {copy.length > 0 && (
              <p className={`${ns}__description wow fadeInUp`} data-wow-duration="1.5s">
                {copy.map(function(p, i) {
                  return <span key={i}>{p.text}</span>
                })}
              </p>
            )}
            {link_title.length > 0 && (
              <a className={`hero__main-link wow fadeInUp`} href={link[0].text} target='_blank' rel="noopener noreferrer" data-wow-duration="1.5s">
                {link_title[0].text}
              </a>
            )}
          </div>
          <div className={`${ns}__col wow fadeInUp`} data-wow-duration="1.5s">
            {milestones.length > 0 && (
              <div className={`${ns}__milestones`}>
                <p className={`wow fadeInUp`}>Company History</p>
                {milestones[0].milestone.length > 0 && milestones.map(function(m, i) {
                  return <p className={`wow fadeInUp`} key={i} data-wow-duration="1.5s">{m.milestone[0].text} </p>
                })}
              </div>
            )}
            <div className={`${ns}__company-founded ${ns}__block-member`}>
              <p className={`${ns}__side-title`}>Company History</p>
              {year_founded.length > 0 && (
                <div className={`${ns}__side-copy`}>Founded {year_founded[0].text} <br/>

                Partnered with ACG&nbsp;
                {year_partnered_with_acg.map(function(p, i) {
                  return <span key={i}>{p.text}<br/></span>
                })}


                </div>
              )}
            </div>
            <div className={`${ns}__team ${ns}__block-member`}>
              <p className={`${ns}__side-title`}>Team</p>
              {team &&
                team.map(function(t, i) {
                  return <div className={`${ns}__side-copy`} key={i}>{t.team_member[0].text}</div>
                })}
            </div>
            {social_links.length > 0 && (
              <div className={`${ns}__social-links ${ns}__block-member`}>
                <p className={`${ns}__side-title`}>Connect</p>
                {social_links.map(function(s, i) {
                  if (s.link.length > 0 && s.link.length > 0) {
                    return (
                      <div className={`${ns}__social-text`} key={i}>
                        <a href={s.link[0].text}>{s.title[0].text}</a>
                      </div>
                    )
                  } else return null
                })}
              </div>
            )}
          </div>
        </div>
      </section>
    )
  }
}

export default PartnerCopyBlock
