import React, { Component } from 'react'

import WOW from 'wow.js/dist/wow.js'

const ns = 'split-columns'

class SplitColumns extends Component {
  constructor(props) {
    super(props)

    this.state = {
      leftColumn: this.props.leftColumn,
      rightColumn: this.props.rightColumn
    }

    new WOW({
      offset: 150
    }).init()
  }
  buildColumns() {
    return Object.keys(this.state).map(key => {
      return (
        <div className={`${ns}__col  wow fadeInUp`} key={key} data-wow-duration="1.5s">
          <h3 className={`${ns}__headline`}>{this.state[key].primary.title[0].text}</h3>
          <ul className={`${ns}__list`}>
            {this.state[key].items.map(function(item, i) {
              return (
                <li className={`${ns}__list--item`} key={i}>
                  <h4 className={`${ns}__title`}>{item.service_item_title[0].text}</h4>
                  <p className={`${ns}__copy`}>{item.service_item_copy[0].text}</p>
                </li>
              )
            })}
          </ul>
        </div>
      )
    })
  }
  render() {
    const customClass = this.props.modifier ? ns + ' ' + ns + '--' + this.props.modifier : ns

    return (
      <section className={`${customClass}`}>
        <img
          className={`${ns}__triangle wow fadeInLeft`}
          src="https://prismic-io.s3.amazonaws.com/alliance-consumer-growth%2F528b0e0c-5655-4e93-ae1d-ca490bfa6a7e_dotty-triangle.png"
          alt="Decorative Triangles"
          data-wow-duration="1.5s"
        />
        <div className="grid-container">{!!this.state.leftColumn && this.buildColumns()}</div>
      </section>
    )
  }
}

export default SplitColumns
