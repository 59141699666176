import React, { Component } from 'react'
import SlickSlider from 'react-slick'

import WOW from 'wow.js/dist/wow.js'

let ns = 'copy-list'

class CopyList extends Component {
  constructor(props) {
    super(props)

    this.settings = {
      customPaging: function(i) {
        return (
          <a href="/">
            <div className="slick-custom-pagination--about" />
          </a>
        )
      },
      dots: true,
      infinite: true,
      speed: 500,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      centerMode: true,
      centerPadding: '0px',
      easing: 'ease'
    }
  }
  comonentDidMount() {
    new WOW({
      offset: 150,
      mobile: false
    }).init()
  }
  render() {
    const customClass = this.props.modifier ? ns + ' ' + ns + '--' + this.props.modifier : ns
    const style = {
      backgroundImage: `url(${this.props.bgImage})`
    }

    return (
      <section className={`${customClass}`} style={ this.props.bgImage ? style : {}}>
        <div className="grid-container wow fadeInUp" data-wow-duration="1.5s">
          {this.props.eyebrow && <p className={`${ns}__eyebrow`}>{this.props.eyebrow}</p>}
          {this.props.title && <h3 className={`${ns}__headline`}>{this.props.title}</h3>}
          {!!this.props.list && (
            <div>
              <ul className={`${ns}__wrapper`}>
                {this.props.list.map(function(item, i) {
                  return (
                    <li className={`${ns}__wrapper--item`} key={i}>
                      <h3 className={`${ns}__title`}>{item.title[0].text}</h3>
                      <p className={`${ns}__copy`}>{item.copy[0].text}</p>
                    </li>
                  )
                })}
              </ul>
              {!!this.props.slider && (
                <div className={`${ns}__slider`}>
                  <SlickSlider {...this.settings}>
                    {this.props.list.map(function(item, i) {
                      return (
                        <li className={`${ns}__wrapper--item`} key={i}>
                          <h3 className={`${ns}__title`}>{item.title[0].text}</h3>
                          <p className={`${ns}__copy`}>{item.copy[0].text}</p>
                        </li>
                      )
                    })}
                  </SlickSlider>
                </div>
              )}
            </div>
          )}
        </div>
      </section>
    )
  }
}

export default CopyList
