import React, { Component } from 'react'
import { connect } from 'react-redux'

// Actions
import { partnerDetailFetchData, fetchLinkedArticles } from '../../store/actions/partnerpage'

// Components
import Hero from '../../Components/Hero'
import Slider from '../../Components/Slider'
import NewsSlider from '../../Components/NewsSlider'
import PartnerCopyBlock from '../../Components/PartnerCopyBlock'
import Loader from '../../Components/Loader'

// Prsimic
import Prismic from 'prismic-javascript'
import { PRISMIC_API_URL } from '../../config'

const ns = 'page-partner-detail'

class PartnerDetail extends Component {
  constructor(props) {
    super(props)

    this.state = {
      partnerPath: this.props.location.pathname.split('/')[2],
      loading: true
    }
  }

  componentDidMount() {
    this.props.fetchPageData(this.state.partnerPath)
    this.props.fetchLinkedArticles(this.state.partnerPath)
    Prismic.getApi(PRISMIC_API_URL)
    .then(api => {
      return api.query(Prismic.Predicates.at('document.type', 'brand_partner_list'), {})
    })
    .then(response => {
      if (response) {
        // console.log(response.results[0])
        this.setState({
          doc: response.results[0]
        })

        this.animationTimeout()
      }
      // console.log(this.state.slider)
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.partnerDetail !== prevProps.partnerDetail) {
      this.animationTimeout()
    }
  }

  // For Page Reload to init WOWjs again
  componentWillReceiveProps(props) {
    if (this.props.partnerDetail !== props.partnerDetail) {
      this.animationTimeout()
    }
  }

  animationTimeout() {
    setTimeout(() => {
      this.setState({
        loading: false
      })
      window.scrollTo(0, 0)
    }, 500)
  }

  checkForEmptyStrings(value) {
    if (value === undefined) {
      return null
    } else {
      return value[0].text
    }
  }

  checkForEmptyArrays(array) {
    if (array.length === 0) {
      return null
    } else {
      return array
    }
  }

  render() {
    const { partnerDetail, partnerLinkedArticles } = this.props
    
    return (
      <div>
        {this.state.loading ? (
          <Loader />
        ) : (
          <div className={`${ns}`}>
            {!!partnerDetail.data ? (
              <div className="page-wrapper">
                {/* {console.log(partnerDetail.data)} */}
                <Hero
                  bgImage={partnerDetail.data.image.url}
                  headline={partnerDetail.data.name[0] ? partnerDetail.data.name[0].text : ''}
                  copy={partnerDetail.data.eyebrow[0] ? partnerDetail.data.eyebrow[0].text : ''}
                  // category={partnerDetail.data.category}
                  modifier="white"
                  link_arrow='partner-brands'
                  link_arrow_title="All brands"
                />

                <PartnerCopyBlock
                  {...partnerDetail.data}
                />

                <NewsSlider
                  headline={partnerDetail.data.name[0].text}
                  slides={this.checkForEmptyArrays(partnerLinkedArticles)}
                  modifier="news"
                />
                <Slider slides={this.checkForEmptyArrays(partnerDetail.data.slider)} />

                {/* Client asked to hide */}
                {/* <BrandList
                  list={brandsData}
                /> */}
              </div>
            ) : null}
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    partnerDetail: state.partnerDetail,
    partnerLinkedArticles: state.partnerLinkedArticles,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchPageData: partnerPath => dispatch(partnerDetailFetchData(partnerPath)),
    fetchLinkedArticles: partnerPath => dispatch(fetchLinkedArticles(partnerPath))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PartnerDetail)
