import React, { Component } from 'react'

// Date Formating
// import { Date } from 'prismic-reactjs'
import Moment from 'moment'

import WOW from 'wow.js/dist/wow.js'

const ns = 'news-list'

class NewsList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      currentCategory: 'All',
      articles: this.props.list
    }

    this.setCategory = this.setCategory.bind(this)
    this.filterCategory = this.filterCategory.bind(this)
  }
  componentDidMount() {
    new WOW({
      offset: 150,
      mobile: false
    }).init()

    this.setState({
      mobileMenu: false
    })
  }

  setCategory(category) {
    this.setState(
      {
        currentCategory: category
      },
      () => {
        this.filterCategory()
      }
    )
  }
  filterCategory() {
    let currentCategory = this.state.currentCategory

    this.setState({
      articles: this.props.list.filter(article => {
        if (
          currentCategory ===
            article.data.category
              .toLowerCase()
              .split(' ')
              .join('-') ||
          currentCategory === 'All'
        ) {
          return article
        } else return null
      })
    })
  }

  handleMobileMenu() {
    this.setState(function(prevState) {
      return { mobileMenu: !prevState.mobileMenu }
    })

    if (!this.state.mobileMenu) {
      // console.log('open')
      document.getElementById('filterButton').classList.add('mobile-menu-open')
      document.getElementById('filterButtonOpen').classList.add('mobile-menu-open')
    } else {
      // console.log('not open')
      document.getElementById('filterButton').classList.remove('mobile-menu-open')
      document.getElementById('filterButtonOpen').classList.remove('mobile-menu-open')
    }
  }

  render() {
    return (
      <section className={`${ns}`}>
        <div className="grid-container  wow fadeInUp" data-wow-duration="1.5s">
          <div className={`${ns}__header`}>
            <h3 className={`${ns}__headline`}>News.</h3>

            {/* Filter Mobile Menu */}
            <div className={`${ns}__filter`}>
              <div className={`${ns}__filter--text`}>Filter By</div>
              <div className={`${ns}__filter--button`} id="filterButtonOpen" onClick={this.handleMobileMenu.bind(this)} />
            </div>

            <div className={`${ns}__categories-wrapper`}>
              <ul className={`${ns}__categories`} id="filterButton">
                <div className={`${ns}__categories--category-title ${ns}__category--item`}>Category:</div>
                <li
                  className={`${ns}__category ${ns}__category--item ${this.state.currentCategory === 'All' ? 'active' : ''}`}
                  onClick={() => this.setCategory('All')}
                >
                  All
                </li>
                <li
                  className={`${ns}__category ${ns}__category--item ${this.state.currentCategory === 'investment-news' ? 'active' : ''}`}
                  onClick={() => this.setCategory('investment-news')}
                >
                  Partner Brand News
                </li>
                <li
                  className={`${ns}__category ${ns}__category--item ${this.state.currentCategory === 'acg-news' ? 'active' : ''}`}
                  onClick={() => this.setCategory('acg-news')}
                >
                  ACG News
                </li>
                {/* <li
                  className={`${ns}__category ${ns}__category--item ${this.state.currentCategory === 'press-releases' ? 'active' : ''}`}
                  onClick={() => this.setCategory('press-releases')}
                >
                  Press Releases
                </li>
                <li
                  className={`${ns}__category ${ns}__category--item ${this.state.currentCategory === 'team-news' ? 'active' : ''}`}
                  onClick={() => this.setCategory('team-news')}
                >
                  Team News
                </li> */}
              </ul>
            </div>
          </div>

          {!!this.state.articles && (
            <ul className={`${ns}__list`}>
              {this.state.articles.length <= 0 && <li className={`${ns}__list--none`}>No results found.</li>}
              {this.state.articles.map(function(item, i) {
                // const date = Date(item.data.publication_date).toString()
                const formattedDate = Moment(item.data.publication_date).format('MMMM D, YYYY')
                return (
                  <li className={`${ns}__list--item wow fadeInUp`} key={i} data-wow-duration="1.5s">
                    <div className={`${ns}__wrapper`}>
                      <a href={item.data.link.url} target="_blank" rel="noopener noreferrer" className={`${ns}__image-link`}>
                        <div className={`${ns}__image-wrapper`}>
                          <img className={`${ns}__image`} alt="props" src={item.data.image.url} />
                        </div>
                        {/* <div className={`${ns}__image`} alt="props" style={{backgroundImage: `url(${item.data.image.url})`}} /> */}
                      </a>
                      <a href={item.data.link.url} target="_blank" rel="noopener noreferrer" className={`${ns}__text-link`}>
                        <p className={`${ns}__category-title`}>{item.data.category}</p>
                      </a>
                      <p className={`${ns}__eyebrow`}>
                        <a href={item.data.link.url} target="_blank" rel="noopener noreferrer" className={`${ns}__eyebrow--link`}>
                          <span>{formattedDate}</span> - &nbsp;
                          <span>{item.data.link_title[0].text ? item.data.link_title[0].text : ''}</span>
                        </a>
                      </p>
                      <a href={item.data.link.url} target="_blank" rel="noopener noreferrer" className={`${ns}__text-link`}>
                        <h3 className={`${ns}__title`}>{item.data.title[0].text}</h3>
                      </a>
                    </div>
                  </li>
                )
              })}
            </ul>
          )}
        </div>
      </section>
    )
  }
}

export default NewsList
