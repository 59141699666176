import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import { handelize, getQueryStringValue } from '../../utils'

import WOW from 'wow.js/dist/wow.js'

const ns = 'partner-list'

class PartnerList extends Component {
  constructor(props) {
    super(props)

    const selectedCategory = getQueryStringValue('focus')

    this.state = {
      partners: null,
      currentCategory: (selectedCategory) ? selectedCategory : 'All',
      currentStatus: 'All',
      categoryList: new Set()
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.list !== prevProps.list) {
      this.setState({
        partners: this.props.list
      })
    }
  }

  componentDidMount() {
    new WOW({
      offset: 150,
      mobile: false
    }).init()

    this.setState({
      partners: this.props.list,
      mobileMenu: false
    }, () => {
      this.filterPartners()
    })

    this.saveCategoryList()
  }

  setCategory(category) {
    this.setState(
      {
        currentCategory: category
      },
      () => {
        this.filterPartners()
      }
    )
  }

  setStatus(status) {
    this.setState(
      {
        currentStatus: status
      },
      () => {
        this.filterPartners()
      }
    )
  }

  filterPartners() {
    let currentCategory = this.state.currentCategory
    let currentStatus = this.state.currentStatus

    let partners = this.props.list.filter(partner => {
        let category = partner.data.category
          .toLowerCase()
          .split(' ')
          .join('-')
        let status = partner.data.status
          .toLowerCase()
          .split(' ')
          .join('-')

        category = handelize(category)

        if ((currentCategory === category || currentCategory === 'All') && (currentStatus === status || currentStatus === 'All')) {
          return partner
        } else return null
      })

    this.setState({ partners })
  }

  saveCategoryList() {
    let categoryList = this.state.categoryList

    this.props.list.map(partner => {
      let category = partner.data.category
      
      categoryList.add(category)
    })

    this.setState({ categoryList })
  }

  handleMobileMenu () {
    this.setState(function(prevState) {
      return { mobileMenu:!prevState.mobileMenu }
    })

    if (!this.state.mobileMenu) {
      document.getElementById('filterButton').classList.add('mobile-menu-open')
      document.getElementById('filterButtonOpen').classList.add('mobile-menu-open')
    } else {
      document.getElementById('filterButton').classList.remove('mobile-menu-open')
      document.getElementById('filterButtonOpen').classList.remove('mobile-menu-open')
    }
  }

  render() {
    return (
      <section className={`${ns}`}>
        <div className="partner-wrapper wow fadeInUp">
          
          {/* Mobile Filter */}
          <div className={`${ns}__filter`} id='filterButtonOpen' onClick={this.handleMobileMenu.bind(this)}>
            <div className={`${ns}__filter--text`}>Filter By</div>
            <div className={`${ns}__filter--button`}></div>
          </div>

          <div className={`${ns}__header-wrapper`}>
            <div className={`${ns}__header`} id='filterButton'>

              <div className={`${ns}__status-wrapper`}>
                <ul className={`${ns}__categories ${ns}__categories--status`}>
                  <p className={`${ns}__status`}>Status:</p>
                  <li
                    className={`${ns}__category ${ns}__category--item ${this.state.currentStatus === 'All' ? 'active' : ''}`}
                    onClick={() => this.setStatus('All')}
                  >
                    All
                  </li>
                  <li
                    className={`${ns}__category ${ns}__category--item ${this.state.currentStatus === 'current' ? 'active' : ''}`}
                    onClick={() => this.setStatus('current')}
                  >
                    Current
                  </li>
                  <li
                    className={`${ns}__category ${ns}__category--item ${this.state.currentStatus === 'realized' ? 'active' : ''}`}
                    onClick={() => this.setStatus('realized')}
                  >
                    Realized
                  </li>

                </ul>
              </div>

              <div className={`${ns}__focus-wrapper`}>
                <ul className={`${ns}__categories ${ns}__categories--focus`}>
                  <p className={`${ns}__focus`}>Focus:</p>
                  <li
                    className={`${ns}__category ${ns}__category--item ${this.state.currentCategory === 'All' ? 'active' : ''}`}
                    onClick={() => this.setCategory('All')}
                  >
                    All
                  </li>
                  {
                    Array.from(this.state.categoryList).map((item, i) => {
                      let handelizedCategory = handelize(item)

                      return (
                        <li
                          className={`${ns}__category ${ns}__category--item ${this.state.currentCategory === handelizedCategory ? 'active' : ''}`}
                          onClick={() => this.setCategory(handelizedCategory)}
                          key={i}
                        >
                          {item}
                        </li>
                      )
                    })
                  }
                </ul>
              </div>
            </div>
          </div>
          {!!this.state.partners && (
            <ul className={`${ns}__list wow fadeInUp`}>
              {this.state.partners.length <= 0 && (
                <li className={`${ns}__list--none`}>There are no Partner Brands in this category.</li>
              )}
              {this.state.partners.map(function(item, i) {
                let partnerLink = '/partner-brands/' + item.uid
                return (
                  <li className={`${ns}__list--item`} key={i}>
                    <Link to={partnerLink}>{item.data.logo.url && <img className={`${ns}__list--image`} src={item.data.logo.url} alt={item.data.name[0].text} />}</Link>
                  </li>
                )
              })}
            </ul>
          )}
        </div>
      </section>
    )
  }
}

export default PartnerList
