import React, { Component } from 'react'

import WOW from 'wow.js/dist/wow.js'

const ns = 'locations'

class Locations extends Component {
  componentDidMount() {
    new WOW({
      offset: 150,
      mobile: false
    }).init()
  }
  render() {
    const customClass = this.props.modifier ? ns + ' ' + ns + '--' + this.props.modifier : ns

    return (
      <section className={`${customClass}`}>
        {/* Decorative Triangle */}
        <img
          className={`${ns}__triangle wow fadeInLeft`}
          src="https://prismic-io.s3.amazonaws.com/alliance-consumer-growth%2F528b0e0c-5655-4e93-ae1d-ca490bfa6a7e_dotty-triangle.png"
          alt="Decorative Triangles"
          data-wow-duration="1.5s"
        />
        <div className="grid-container  wow fadeInUp" data-wow-duration="1.5s">
          {!!this.props.locations &&
            this.props.locations.map(function(item, i) {
              // console.log(item)
              return (
                <div className={`${ns}__col`} key={i}>
                  <div className={`${ns}__city-wrapper`}>
                    <div
                      className={`${ns}__city ${ns}__city--${item.city_abbreviation[0].text
                        .split(' ')
                        .join('-')
                        .toLowerCase()}`}
                      style={{ backgroundImage: `url(${item.location_image.url})` }}
                    />
                    <p>{item.city_abbreviation[0].text}</p>
                  </div>
                  <p className={`${ns}__address`}>
                    {item.address.map(function(address, i) {
                      return <span key={i}>{address.text}</span>
                    })}
                  </p>
                </div>
              )
            })}
        </div>
      </section>
    )
  }
}

export default Locations
