import React from 'react'

const About = (props) => {
  const ns = props.ns
  return (
    <section className={`${ns}__about-wrapper`}>
      <div className={`grid-container`}>
        <div className={`${ns}__about`} data-wow-duration="1.5s">
          <div className={`${ns}__about--info-wrapper wow fadeInUp`}>
            <h1 className={`${ns}__headline-3 ${ns}__headline-3-about`}>{props.headline}</h1>
            <p className={`${ns}__copy`}>{props.copy}</p>
            <a href="/about" className="hero__main-link">
              {props.link}
            </a>
          </div>
        </div>
      </div>
      <div className={`${ns}__triangle2-wrapper`}>
        <img
          className={`${ns}__triangle2 wow fadeInDown`}
          src="https://prismic-io.s3.amazonaws.com/alliance-consumer-growth%2F1a4d0cb7-cfc4-4555-bc69-6f7c7b0224ad_half-triangle2x.png"
          alt="Decorative Triangles"
          data-wow-duration="1.5s"
        />
      </div>
    </section>
  )
}

export default About