import React, { Component } from 'react'
import SlickSlider from 'react-slick'

import WOW from 'wow.js/dist/wow.js'
import Moment from 'moment'

const ns = 'slider-fancy'

class NewsSlider extends Component {
  constructor(props) {
    super(props)

    this.settings = {
      dots: true,
      infinite: true,
      speed: 500,
      arrows: true,
      //slidesToShow: this.props.slides.length > 3 ? 3 : 1,
      slidesToShow: 3,
      slidesToScroll: 1,
      centerMode: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
            centerPadding: '50px'
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0,
            centerPadding: '0px'
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerPadding: '0px'
          }
        }
      ]
    }
  }

  comonentDidMount() {
    new WOW({
      offset: 150,
      mobile: false
    }).init()
  }

  render() {
    const customClass = this.props.modifier ? ns + ' ' + ns + '--' + this.props.modifier : ns
    const { slides } = this.props

    if (slides && slides.length > 2) {
      return (
        <section className={`${customClass} wow fadeInUp`} data-wow-duration="1.5s">
          <h3 className={`${ns}__headline`}>{this.props.headline} in the news.</h3>
          <SlickSlider {...this.settings}>
            {slides.map(function(slide, i) {
              // const date = Date(slide.data.publication_date).toString()
              const formattedDate = Moment(slide.data.publication_date).format('MMMM D, YYYY')

              return (
                <div key={i} className={`${ns}__slide ${ns}__slide--index-${i}`}>
                  <a href={slide.data.link.url}>
                    <div className="news-list__image-wrapper">
                      <img src={slide.data.image.url} alt={i} className="news-list__image" />
                    </div>
                    <p className={`${ns}__eyebrow news-list__eyebrow news-list__eyebrow--link`}>
                      <span>{!!slide.data.publication_date && formattedDate}</span> --
                      <span>{!!slide.data.link_title[0].text && slide.data.link_title[0].text}</span>
                    </p>
                    <h4 className={`${ns}__title news-list__title`}>{slide.data.title[0].text}</h4>
                  </a>
                </div>
              )
            })}
          </SlickSlider>
        </section>
      )
    } else if (slides && slides.length <= 2) {
        return (
          <section className={`${customClass} ${customClass}--two  wow fadeInUp`} data-wow-duration="1.5s">
            <h3 className={`${ns}__headline`}>{this.props.headline} in the news.</h3>
              {slides.map(function(slide, i) {
                // const date = Date(slide.data.publication_date).toString()
                const formattedDate = Moment(slide.data.publication_date).format('MMMM D, YYYY')
                
                return (
                  <div key={i} className={`${ns}__slide ${ns}__slide--two ${ns}__slide--index-${i}`}>
                    <a href={slide.data.link.url}>
                      <div className="news-list__image-wrapper">
                        <img src={slide.data.image.url} alt={i} className="news-list__image" />
                      </div>
                      <p className={`${ns}__eyebrow news-list__eyebrow news-list__eyebrow--link`}>
                        <span>{!!slide.data.publication_date && formattedDate}</span> --
                        <span>{!!slide.data.link_title[0].text && slide.data.link_title[0].text}</span>
                      </p>
                      <h4 className={`${ns}__title news-list__title`}>{slide.data.title[0].text}</h4>
                    </a>
                  </div>
                )
              })}
          </section>   
        )   
    } 
    else {
      return null
    }
  }
}

export default NewsSlider
